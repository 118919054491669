
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-6">
        <h2>Users report</h2>
      </div>
      <div class="col-md-6">
        <button class="btn btn-success float-right" v-b-modal.export-users> Export </button>
      </div>

    </div>



    <b-table striped hover :items="users" :fields="columns">
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(role)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>

      <template v-slot:cell(username)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>

      <template v-slot:cell(phoneNumber)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>

      <template v-slot:cell(status)="data">
        <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'ACTIVE'" >
								Active
							</span>
              <span  class="badge badge-warning"  v-else-if="data.value == 'INACTIVE'" >
								Inactive
							</span>
              <span class="badge badge-danger"  v-else-if="data.value == 'SUSPENDED'" >
								Suspended
							</span>
        </div>
      </template>
      <template v-slot:cell(options)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-dropdown text="Action">
          <b-dropdown-item href="#" @click="openEditUserModal(data.item)">Edit</b-dropdown-item>
          <!--<b-dropdown-item href="#" @click="openDeleteUser(data.item)">Delete</b-dropdown-item>-->
        </b-dropdown>

      </template>

    </b-table>

    <b-modal id="export-users" title="Export users" @cancel="true" @ok="exportToExcel"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Please enter your email address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="email_address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>



  </div>
</template>
<script>

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

  import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      email_address:"",
      users:[],
      roles:[],
      columns:["No","name","phoneNumber","username","role","status"],
      nps_entities:[],
      ranks:[],
      user:{
        name:"",
        username:"",
        phoneNumber:"",
        role:"",
        tag:""
      },
      isLoading: false,
      fullPage: true,
      filteredRanks:[]
    };
  },
  computed: {

  },
  components: {
    Loading
  },
  mounted (){
    this.getUsersData();
    this.getRolesData();
  },

  methods: {
    ...mapActions(["addUser","editUser","deleteUser","getUsers","getRoles"]),

    getRolesData(){
      let self = this;
      this.getRoles()
        .then((roles) => {
          self.roles = roles;
        })
        .catch(error => {

        })
    },

    openEditUserModal(user){

      this.user = user;

      console.log(user);



      this.$bvModal.show('edit-user');

    },
    openDeleteUser(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.user.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteUser();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    confirmDeleteUser(){
      this.isLoading = true;

      let self = this;

      this.deleteUser({user_id: this.user.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    getUsersData(){
      let self = this;
      this.getUsers()
        .then(function (users){
          console.log(users)
          self.users = users;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    validateBeforeSubmitEditUser(){
      this.isLoading = true;

      let self = this;

      this.editUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.user.name = "";
        self.user.username = "";
        self.user.phoneNumber = "";
        self.user.role = "";
        self.user.tag = "";

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },
    validateBeforeSubmitNewUser(){
      this.isLoading = true;

      let self = this;

      this.addUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.user.name = "";
        self.user.username = "";
        self.user.phoneNumber = "";
        self.user.role = "";
        self.user.tag = "";


        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    }
  }
};
</script>
